<template>
  <responsive-menu v-model="dataMenu">
    <div class="d-flex mx-4">
      <column-picker
        :attach="false"
        :clearable="true"
        :label="$t('t.Column')"
        :filters="{ 'document-types': cameleonDocTypes}"
        :selected-col-id.sync="computedColumnId"
        :consolidated.sync="computedConsolidated"
        :selected-cur-id.sync="computedCurrency"
        dense
        sum-cols-only
        no-cols-relation
        no-cols-aging
      />
    </div>
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        height=32
        icon
        width=32
      >
        <v-icon>
          {{$icon('i.Settings')}}
        </v-icon>
      </v-btn>
    </template>
  </responsive-menu>

</template>

<script>
import IncExc from '@/pages/search/controllers/inc-exc'

export default {
  components: {
    ColumnPicker: () => import('@/components/column-picker'),
    ResponsiveMenu: () => import('@/pages/search/components/responsive-menu')
  },
  data () {
    return {
      dataColumnId: null,
      dataConsolidated: null,
      dataCurrency: null,
      dataMenu: false
    }
  },
  computed: {
    computedColumnId: {
      get () {
        return this.dataColumnId
      },
      set (v) {
        this.dataColumnId = v
        this.emitDocument()
      }
    },
    computedConsolidated: {
      get () { return this.dataConsolidated },
      set (v) {
        this.dataConsolidated = v
        this.emitDocument()
      }
    },
    computedCurrency: {
      get () { return this.dataCurrency },
      set (v) {
        this.dataCurrency = v
        this.emitDocument()
      }
    },
    cameleonDocTypes () {
      const cameleonDocTypes = new IncExc()
      cameleonDocTypes.include([this.documentType])
      return cameleonDocTypes
    }
  },
  methods: {
    emitDocument () {
      let args = null

      if (this.dataColumnId) {
        args = {
          col: this.dataColumnId,
          consolidated: this.dataConsolidated,
          currency: this.dataCurrency
        }
      }

      if (!this.lodash.isEqual(this.value, args)) {
        this.$emit('input', args)
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.dataColumnId = v?.col
        this.dataConsolidated = v?.consolidated
        this.dataCurrency = v?.currency

        this.emitDocument()
      }
    }
  },
  props: {
    dense: Boolean,
    documentType: String,
    value: Object
  }
}
</script>

<style lang="stylus" scoped></style>
